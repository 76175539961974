module controllers {
    export module logistics {
        interface ICommercialInvoiceSkuScope extends ng.IScope {
            commercialInvoiceUpdateCtrl: controllers.logistics.CommercialInvoiceUpdateCtrl,
            consignmentUpdateCtrl: controllers.consigment.ConsignmentExportUpdateCtrl,
            tariffCountryId: number,
        }
        interface ICommercialInvoiceSkuParams extends ng.ui.IStateParamsService {
            cisId: number;
            comId: number;
        }

        export class commercialInvoiceSkuUpdateCtrl {
            static $inject = ["$scope", '$rootScope', 'generalService', '$q', '$anchorScroll', 'bsLoadingOverlayService', '$state', '$timeout', "$stateParams",
                "commercialInvoiceSkuService", "commercialInvoiceItemTrackingService", "commercialInvoiceNationalMeasureCodeService", "commercialInvoiceCountryGroupService",
                "unitOfMeasureService",
                "countryService",
                "lookupService",
                "classificationValueService",
                "tariffCountryGroupService",
                "tariffCountryGroupOriginService",
                "tariffPreferenceCodeService",
                "vatRateService",
                "skuService",
                "productColourService", "productSizeService", "packConfigurationService", "supplierService",
                "$uibModal",
                "consignmentService",
                "menuService",
                'ENV',
            ];

            public Sku: interfaces.logistics.ICommercialInvoiceSKU;
            cisId: number;
            comId: number;
            breadCrumbDesc: string;

            apiUoM: uiGrid.IGridApi;
            apiCIT: uiGrid.IGridApi;
            apiTrade: uiGrid.IGridApi;
            apiCG: uiGrid.IGridApi;

            tariffCountryId: number;
            targetCountryId: number;
            countryId: number;

            tariffTypes: interfaces.applicationcore.IDropdownModel[];
            consignmentTypeDefaults: interfaces.consignment.ConsignmentTypeDefaults;

            allowCostPriceOverride: boolean;
            allowTariffInvoice: boolean;

            varianceAuthUser: interfaces.applicationcore.IDropdownModel;

            selectedInternationalTrade: interfaces.logistics.ICommercialInvoiceNationalMeasureCode;
            LockdownType: Enum.EnumLockdownType;

            constructor(private $scope: ICommercialInvoiceSkuScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                private generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService,
                private $anchorScroll: ng.IAnchorScrollService,
                private bsLoadingOverlayService,
                private $state: ng.ui.IStateService,
                private $timeout: ng.ITimeoutService,
                private $stateParams: ICommercialInvoiceSkuParams,
                public commercialInvoiceSkuService: interfaces.logistics.ICommercialInvoiceSkuService,
                public commercialInvoiceItemTrackingService: interfaces.logistics.ICommercialInvoiceItemTrackingService,
                public commercialInvoiceNationalMeasureCodeService: interfaces.logistics.ICommercialInvoiceNationalMeasureCodeService,
                public commercialInvoiceCountryGroupService: interfaces.logistics.ICommercialInvoiceCountryGroupService,
                public unitOfMeasureService: interfaces.master.IUnitOfMeasureService,
                public countryService: interfaces.applicationcore.ICountryService,
                public lookupService: interfaces.applicationcore.ILookupService,
                public classificationValueService: interfaces.applicationcore.IClassificationValueService,
                public tariffCountryGroupService: interfaces.tariff.ITariffCountryGroupService,
                public tariffCountryGroupOriginService: interfaces.tariff.ITariffCountryGroupOriginService,
                public tariffPreferenceCodeService: interfaces.tariff.ITariffPreferenceCodeService,
                public vatRateService: interfaces.master.IVatRateService,
                public skuService: interfaces.master.ISKUService,
                public productColourService: interfaces.master.IProductColourService, public productSizeService: interfaces.master.IProductSizeService,
                public packConfigurationService: interfaces.master.IPackConfigurationService,
                private supplierService: interfaces.master.ISupplierService,
                private $uibModal: ng.ui.bootstrap.IModalService,
                public consignmentService: interfaces.consignment.IConsignmentService,
                private menuService: interfaces.applicationcore.IMenuService,
                private ENV: interfaces.applicationcore.serverConfig) {

                this.cisId = this.$stateParams.cisId;
                this.comId = this.$stateParams.comId;

                var loadPromises: ng.IPromise<any>[] = [];

                loadPromises.push(this.loadSku($stateParams.cisId, $stateParams.comId));
                loadPromises.push(this.loadTariffTypes());
                loadPromises.push(this.loadConsignmentTypeDefaults());
                loadPromises.push(this.getAllowPriceOverrideRight());
                loadPromises.push(this.getAllowTariffInvoiceRight());
                
                this.LockdownType = this.$scope.commercialInvoiceUpdateCtrl.CommercialInvoice.LockdownType;

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'ci.skuupdate'
                },
                    () => {
                        return $q.all(loadPromises);
                    });
            }

            getAllowTariffInvoiceRight() {
                this.allowTariffInvoice = false;                
                return this.menuService.getGTSConnectMenuItem(this.$scope.consignmentUpdateCtrl.consignment.IsInbound?654:653).get((result: interfaces.applicationcore.IMenu) => {
                    if (result.name) {
                        this.allowTariffInvoice = true;
                    }
                }).$promise;
            }

            public loadSku(cisId: number, comId: number) {
                this.countryId = 249;
                if (this.$scope.consignmentUpdateCtrl.consignment) {

                    if (this.$scope.consignmentUpdateCtrl.consignment.IsInbound) {
                        this.countryId = this.$scope.consignmentUpdateCtrl.consignment.UnloadingCountry.Id;
                        this.targetCountryId = this.$scope.consignmentUpdateCtrl.consignment.LoadingCountry.Id;
                    } else {
                        this.countryId = this.$scope.consignmentUpdateCtrl.consignment.LoadingCountry.Id;
                        this.targetCountryId = this.$scope.consignmentUpdateCtrl.consignment.UnloadingCountry.Id;
                    }
                }

                return this.commercialInvoiceSkuService.getDetail().query({ cisId: cisId, comId: comId, cntId: this.countryId }, (data: interfaces.logistics.ICommercialInvoiceSKU) => {
                    this.Sku = data;

                    this.breadCrumbDesc = data.CommercialInvoiceLine.SequenceNumber + " - " + data.SKU.Display;

                    this.gvwCISIT.data = this.Sku.ItemTrackingList;
                    this.gvwCISUoM.data = this.Sku.UoMList;
                    this.gvwCISTrade.data = this.Sku.CommercialInvoiceLine.CommercialInvoiceNationalMeasureCodeList;
                    this.gvwCISCountryGroup.data = this.Sku.CommercialInvoiceLine.CommercialInvoiceCountryGroupList;

                    if (this.$scope.commercialInvoiceUpdateCtrl.CommercialInvoice) {
                        this.Sku.CommercialInvoiceLine.OwnerEntityId = this.$scope.commercialInvoiceUpdateCtrl.CommercialInvoice.OwnerEntity.Id;
                    }

                    this.recalculateValues();


                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }



            SaveCISKU(): ng.IPromise<boolean> {
                var deferre = this.$q.defer<boolean>();

                if (this.apiUoM) {
                    this.Sku.UoMList = _.map(this.apiUoM.rowEdit.getDirtyRows(this.apiUoM.grid), (o) => { return o.entity });
                } else {
                    this.Sku.UoMList = [];
                }

                if (this.apiCIT) {
                    this.Sku.ItemTrackingList = _.map(this.apiCIT.rowEdit.getDirtyRows(this.apiCIT.grid), (o) => { return o.entity });
                } else {
                    this.Sku.ItemTrackingList = [];
                }

                if (this.apiTrade) {
                    this.Sku.CommercialInvoiceLine.CommercialInvoiceNationalMeasureCodeList = _.map(this.apiTrade.rowEdit.getDirtyRows(), 'entity');
                } else {
                    this.Sku.CommercialInvoiceLine.CommercialInvoiceNationalMeasureCodeList = [];
                }

                if (this.apiCG) {
                    this.Sku.CommercialInvoiceLine.CommercialInvoiceCountryGroupList = _.map(this.apiCG.rowEdit.getDirtyRows(this.apiCG.grid), (o) => { return o.entity });
                } else {
                    this.Sku.CommercialInvoiceLine.CommercialInvoiceCountryGroupList = [];
                }

                this.commercialInvoiceSkuService.save().save(this.Sku, (data: interfaces.applicationcore.IMessageHandler) => {
                    this.generalService.displayMessageHandler(data);
                    if (!this.Sku.Id) {
                        this.Sku.Id = Number(data.ID);
                    }

                    if (this.apiUoM) {
                        this.apiUoM.rowEdit.setRowsClean(this.Sku.UoMList);
                    }

                    if (this.apiCIT) {
                        this.apiCIT.rowEdit.setRowsClean(this.Sku.ItemTrackingList);
                    }

                    if (this.apiUoM) {
                        this.apiUoM.rowEdit.setRowsClean(this.Sku.UoMList);
                    }

                    if (this.apiTrade) {
                        this.apiTrade.rowEdit.setRowsClean(this.Sku.CommercialInvoiceLine.CommercialInvoiceNationalMeasureCodeList);
                    }


                    if (this.apiCG) {
                        this.apiCG.rowEdit.setRowsClean(this.Sku.CommercialInvoiceLine.CommercialInvoiceCountryGroupList);
                    }

                    deferre.resolve(true);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    deferre.resolve(false);
                });

                return deferre.promise;
            };

            Save() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'ci.skuupdate'
                },
                    () => {
                        return this.SaveCISKU().then((data: boolean) => {
                            if (data) {
                                this.loadSku(this.$stateParams.cisId, this.$stateParams.comId);
                                this.$anchorScroll("topAnchor");

                                if (this.$stateParams.manId) {
                                    if (this.$scope.consignmentUpdateCtrl.consignment.IsInbound) {
                                        this.$state.go("auth.Manifests.Update.ConsignmentImport.CommercialInvoice.Sku", { cisId: this.Sku.Id });
                                    }
                                    else {
                                        this.$state.go("auth.Manifests.Update.ConsignmentExport.CommercialInvoice.Sku", { cisId: this.Sku.Id });
                                    }
                                }
                                else {
                                    if (this.$scope.consignmentUpdateCtrl.consignment.IsInbound) {
                                        this.$state.go("auth.ConsignmentImports.Update.CommercialInvoice.Sku", { cisId: this.Sku.Id });
                                    }
                                    else {
                                        this.$state.go("auth.ConsignmentExports.Update.CommercialInvoice.Sku", { cisId: this.Sku.Id });
                                    }
                                }

                            }
                        });
                    });

            }

            SaveClose() {


                this.bsLoadingOverlayService.wrap({
                    referenceId: 'ci.skuupdate'
                },
                    () => {
                        return this.SaveCISKU().then((data) => {
                            if (data) {
                                this.$state.go("^");
                            } else {
                                this.$anchorScroll("topAnchor");
                            }
                        });
                    });
            }

            Close() {
                this.$state.go("^");
            }


            gvwCISIT: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: false,
                useExternalFiltering: false,
                enableCellEdit: true,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,
                enableFullRowSelection: false,
                useExternalPagination: false,
                enableRowHeaderSelection: true,
                enableHorizontalScrollbar: 2,
                rowEditWaitInterval: -1,
                onRegisterApi: (gridApi) => { this.registerSITApi(gridApi); },
                cellEditableCondition: () => { return this.$scope.commercialInvoiceUpdateCtrl.CommercialInvoice ? this.$scope.commercialInvoiceUpdateCtrl.CommercialInvoice.LockdownType < 2 : true },
                columnDefs: [{
                    name: 'Qty',
                    field: 'Quantity',
                    displayName: 'Qty',
                    enableCellEditOnFocus: true,
                    editableCellTemplate: `
                                <form name="inputForm">
                                    <p class="input-group-sm">
                                        <input type="number" ignore-wheel name="Qty" ng-model="MODEL_COL_FIELD" class="form-control" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor step="0.01" min="0" />
                                    </p>
                                </form>`,
                    cellClass: 'text-right',
                    enableSorting: false,
                    width: 150
                }, {
                    name: 'LotNumber',
                    field: 'LotNumber',
                    displayName: 'Lot Number',
                    enableCellEditOnFocus: true,
                    editableCellTemplate: `
                                <form name="inputForm">
                                    <p class="input-group-sm">
                                        <input type="text" ignore-wheel name="LotNumber" ng-model="MODEL_COL_FIELD" class="form-control" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor/>
                                    </p>
                                </form>`,
                    cellClass: 'text-left',
                    enableSorting: false,
                    width: 150
                }
                    , {
                    name: 'SerialNumber',
                    field: 'SerialNumber',
                    displayName: 'Serial Number',
                    enableCellEditOnFocus: true,
                    editableCellTemplate: `
                                <form name="inputForm">
                                    <p class="input-group-sm">
                                        <input type="text" ignore-wheel name="SerialNumber" ng-model="MODEL_COL_FIELD" class="form-control" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor/>
                                    </p>
                                </form>`,
                    cellClass: 'text-left',
                    enableSorting: false,
                    width: 150
                }
                    , {
                    name: 'TankNumber',
                    field: 'TankNumber',
                    displayName: 'Tank Number',
                    enableCellEditOnFocus: true,
                    editableCellTemplate: `
                                <form name="inputForm">
                                    <p class="input-group-sm">
                                        <input type="text" ignore-wheel name="TankNumber" ng-model="MODEL_COL_FIELD" class="form-control" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor/>
                                    </p>
                                </form>`,
                    cellClass: 'text-left',
                    enableSorting: false,
                    width: 150
                },
                {
                    name: 'ProductionDate',
                    displayName: 'Production Date',
                    field: 'ProductionDate',
                    enableCellEditOnFocus: true,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: "YYYY/MM/DD"}}</div>`,
                    editableCellTemplate: `
                                <form name="inputForm">
                                        <gts-date format="yyyy/MM/dd" ng-model="MODEL_COL_FIELD" name="{{MODEL_COL_FIELD.$$hashKey}}-productionDate"></gts-date>
                                </form>`,
                    enableSorting: false,
                    width: 120
                }
                    ,
                {
                    name: 'ExpiryDate',
                    displayName: 'Expiry Date',
                    field: 'ExpiryDate',
                    enableCellEditOnFocus: true,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: "YYYY/MM/DD"}}</div>`,
                    editableCellTemplate: `
                                <form name="inputForm">
                                        <gts-date format="yyyy/MM/dd" ng-model="MODEL_COL_FIELD" name="{{MODEL_COL_FIELD.$$hashKey}}-expiryDate"></gts-date>
                                </form>`,
                    enableSorting: false,
                    width: 120
                },
                {
                    name: 'ScrapDate',
                    displayName: 'Scrap Date',
                    field: 'ScrapDate',
                    enableCellEditOnFocus: true,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: "YYYY/MM/DD"}}</div>`,
                    editableCellTemplate: `
                                <form name="inputForm">
                                        <gts-date format="yyyy/MM/dd" ng-model="MODEL_COL_FIELD" name="{{MODEL_COL_FIELD.$$hashKey}}-scrapDate"></gts-date>
                                </form>`,
                    enableSorting: false,
                    width: 120
                }

                ]
            };

            registerSITApi(gridApi: uiGrid.IGridApi) {
                this.apiCIT = gridApi;
            }

            insertIT() {
                this.Sku.ItemTrackingList.push(<interfaces.logistics.ICommercialInvoiceItemTracking>{
                    Id: 0,
                    CommercialInvoiceSKUId: this.Sku.Id,
                    IsActive: true
                });

                this.gvwCISIT.data = this.Sku.ItemTrackingList;
               
            }

            removeIT() {

                if (this.apiCIT.selection.getSelectedRows().length > 0) {

                    this.generalService.displayConfirmationBox("Confirm Remove", "Are you sure you want to remove the Item Tracking?").then((result) => {
                        var selectedRow: interfaces.logistics.ICommercialInvoiceItemTracking = this.apiCIT.selection.getSelectedRows()[0];
                        if (result && selectedRow.Id <= 0) {
                            _.remove(this.Sku.ItemTrackingList, selectedRow);
                        } else if (result) {
                            this.bsLoadingOverlayService.wrap({
                                referenceId: 'ci.skuupdate'
                            },
                                () => {
                                    return this.commercialInvoiceItemTrackingService.remove(selectedRow.Id).remove((result) => {
                                        this.generalService.displayMessageHandler(result);
                                        if (!result.HasErrorMessage)
                                            this.loadSku(this.$stateParams.cisId, this.$stateParams.comId);
                                    }, (errorResponse) => {
                                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                                    }).$promise;
                                });
                        }
                    });

                }
            }

            gvwCISUoM: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: false,
                useExternalFiltering: false,
                enableCellEdit: true,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,
                enableFullRowSelection: false,
                useExternalPagination: false,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                rowEditWaitInterval: -1,
                onRegisterApi: (gridApi) => { this.registerUoMApi(gridApi); },
                cellEditableCondition: () => { return this.$scope.commercialInvoiceUpdateCtrl.CommercialInvoice ? this.$scope.commercialInvoiceUpdateCtrl.CommercialInvoice.LockdownType < 2 : true },
                columnDefs: [{
                    name: 'UoM',
                    displayName: 'UoM',
                    field: 'UoM',
                    enableCellEditOnFocus: true,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD.Display}}</div>`,
                    editableCellTemplate: `
                                <form name="inputForm">
                                    <gts-dropdown ng-disabled="MODEL_COL_FIELD && MODEL_COL_FIELD.Id" ui-select-wrap name="{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.commercialInvoiceSkuUpdateCtrl.loadUoMList()"></gts-dropdown>
                                </form>`,
                    enableSorting: false,
                    width: 250
                }, {
                    name: 'Qty',
                    field: 'Quantity',
                    displayName: 'Qty',
                    enableCellEditOnFocus: true,
                    editableCellTemplate: `
                                <form name="inputForm">
                                    <p class="input-group-sm">
                                        <input type="number" ng-disabled="row.entity.IsGrossWeight || row.entity.IsNettWeight || row.entity.IsCube" ignore-wheel name="orderedQty" ng-model="MODEL_COL_FIELD" class="form-control" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor step="0.000001" min="0" />
                                    </p>
                                </form>`,
                    cellClass: 'text-right',
                    enableSorting: false,
                    width: 150
                },
                {
                    name: 'IsActive',
                    field: 'IsActive',
                    displayName: 'Active',
                    enableCellEditOnFocus: true,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD ? 'Yes' : 'No'}}</div>`,
                    editableCellTemplate: `
                            <form name="inputForm">
                                <p class="input-group-sm">
                                    <input type="checkbox" ng-model="MODEL_COL_FIELD" ng-click="grid.appScope.commercialInvoiceSkuUpdateCtrl.changeUoM(row.entity)">
                                </p>
                            </form>`,
                    cellClass: 'text-right',
                    enableSorting: false,
                    width: 150
                }]
            };

            registerUoMApi(gridApi: uiGrid.IGridApi) {
                this.apiUoM = gridApi;
            }

            changeUoM(commercialInvoiceSKUUom: interfaces.logistics.ICommercialInvoiceSKUUoM) {
                this.apiUoM.rowEdit.setRowsDirty([commercialInvoiceSKUUom]);
            }

            loadUoMList() {
                if (this.Sku && this.Sku.CommercialInvoiceLine) {
                    return this.unitOfMeasureService.getUoMDropdown().query({ ownerEntityId: this.$scope.commercialInvoiceUpdateCtrl.CommercialInvoice.OwnerEntity.Id }).$promise;
                } else {
                    var defered = this.$q.defer();
                    defered.resolve([]);
                    return defered.promise;
                }
            }

            insertUoM() {
                this.Sku.UoMList.push(<interfaces.logistics.ICommercialInvoiceSKUUoM>{
                    CommercialInvoiceSKUId: this.Sku.Id,
                    IsActive: true
                });
                this.gvwCISUoM.data = this.Sku.UoMList;
            }


            gvwCISTrade: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: false,
                useExternalFiltering: false,
                enableCellEdit: true,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,
                enableFullRowSelection: false,
                useExternalPagination: false,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                rowEditWaitInterval: -1,
                onRegisterApi: (gridApi) => { this.registerTradeApi(gridApi); },
                cellEditableCondition: () => { return this.$scope.commercialInvoiceUpdateCtrl.CommercialInvoice ? this.$scope.commercialInvoiceUpdateCtrl.CommercialInvoice.LockdownType < 2 : true },
                columnDefs: [{
                    name: "EDIT",
                    displayName: "",
                    enableFiltering: false,
                    enableCellEdit: false,
                    cellTemplate: `
                    <div class="GridButton"> 
                        <button type="button" class="btn btn-default btn-sm" ng-disabled="!grid.appScope.commercialInvoiceSkuUpdateCtrl.allowTariffInvoice" ng-click="grid.appScope.commercialInvoiceSkuUpdateCtrl.editNationalMeasureCode(row.entity)">
                            <span class="fa fa-pencil"></span>
                        </button>
                    </div>`,
                    enableSorting: false,
                    enableColumnMenu: false,
                    width: 35
                }, {
                    name: 'CODETYPE',
                    displayName: 'Code Type',
                    field: 'CodeTypeClassificationValueDescription',
                    enableSorting: true,
                    enableCellEdit: false,
                    width: 150
                }, {
                    name: 'COUNTRY',
                    displayName: 'Country',
                    field: 'NationalMeasureCountryCode',
                    enableSorting: true,
                    enableCellEdit: false,
                    width: 75
                }, {
                    name: 'CODE',
                    displayName: 'Code',
                    field: 'NationalMeasureCode',
                    enableSorting: true,
                    enableCellEdit: false,
                    width: 150
                },
                {
                    name: 'ImportDuty',
                    displayName: 'Import Duty',
                    field: 'ImportDuty',
                    enableSorting: true,
                    enableCellEdit: false,
                    width: 150
                }, {
                    name: "PreviousConsignmentId",
                    displayName: "",
                    field: "PreviousConsignmentId",
                    enableHiding: false,
                    width: 30,
                    enableSorting: false,
                    enableColumnMenu: false,
                    cellTemplate: ` <div class="ui-grid-cell-contents" 
                                                    ng-click="grid.appScope.commercialInvoiceSkuUpdateCtrl.ViewPreviousConsignment(row.entity.PreviousConsignmentId,row.entity.PreviousConsignmentIsInbound)" 
                                                    ng-if="row.entity.PreviousConsignmentId"
                                                    data-toggle="tooltip" data-placement="top" title="{{row.entity.PreviousConsignmentNumber}}"
                                                    >
                                            <span class="fa fa-eye"></span>
                                        </div>`,
                },
                {
                    name: 'PreviousProcedureMRN',
                    field: 'PreviousProcedureMRN',
                    displayName: 'Previous Procedure MRN',
                    enableCellEditOnFocus: true,
                    editableCellTemplate: `
                                <form name="inputForm">
                                    <p class="input-group-sm">
                                        <input  type="text" name="{{MODEL_COL_FIELD.$$hashKey}}-PreviousProcedureMRN" ng-model="MODEL_COL_FIELD" class="form-control" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor />
                                    </p>
                                </form>`,
                    enableSorting: true,
                    width: 250
                },
                {
                    name: 'PreviousProcedureLineNo',
                    field: 'PreviousProcedureLineNo',
                    displayName: 'Previous Procedure Line',
                    enableCellEditOnFocus: true,
                    editableCellTemplate: `
                                <form name="inputForm">
                                    <p class="input-group-sm">
                                        <input type="text" name="{{MODEL_COL_FIELD.$$hashKey}}-PreviousProcedureLineNo" ng-model="MODEL_COL_FIELD" class="form-control" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor />
                                    </p>
                                </form>`,
                    enableSorting: true,
                    width: 175
                }, {
                    name: 'CPCCode',
                    field: 'CPCCode',
                    displayName: 'CPC',
                    enableCellEditOnFocus: true,
                    editableCellTemplate: `
                                <form name="inputForm">
                                    <p class="input-group-sm">
                                        <input type="text" name="{{MODEL_COL_FIELD.$$hashKey}}-CPC" ng-model="MODEL_COL_FIELD" class="form-control" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor />
                                    </p>
                                </form>`,
                    enableSorting: true,
                    width: 200
                },
                {
                    name: 'CPCCode2',
                    field: 'CPCCode2',
                    displayName: 'CPC 2',
                    enableCellEditOnFocus: true,
                    editableCellTemplate: `
                                <form name="inputForm">
                                    <p class="input-group-sm">
                                        <input type="text" name="{{MODEL_COL_FIELD.$$hashKey}}-CPC" ng-model="MODEL_COL_FIELD" class="form-control" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor />
                                    </p>
                                </form>`,
                    enableSorting: true,
                    width: 200
                },
                {
                    name: "IsActive",
                    displayName: "Active",
                    enableFiltering: false,
                    enableCellEdit: true,
                    enableCellEditOnFocus: true,
                    cellEditableCondition: () => { return this.$scope.commercialInvoiceUpdateCtrl.CommercialInvoice ? this.$scope.commercialInvoiceUpdateCtrl.CommercialInvoice.LockdownType < 2 : true },
                    cellTemplate: `<input type="checkbox" ng-disabled="false" ng-model="row.entity.IsActive" ng-click="grid.appScope.commercialInvoiceSkuUpdateCtrl.changeNationalMeasureCode(row.entity)">`,
                    enableSorting: true,
                    editableCellTemplate: ` <form name="inputForm">
                                                    <p class="input-group-sm">
                                                        <input type="checkbox" ng-disabled="false" ng-model="row.entity.IsActive" ng-click="grid.appScope.commercialInvoiceSkuUpdateCtrl.changeNationalMeasureCode(row.entity)">
                                                    </p>
                                                </form>`,
                    width: 75
                }
                ]
            };

            ViewPreviousConsignment(PreviousConsignmentId:number, PreviousConsignmentIsInbound:boolean){
                var url = "";
                if (PreviousConsignmentIsInbound){
                    url = '#!/ConsignmentImports/UpdateImport/'+PreviousConsignmentId;
                }
                else{                    
                    url = '#!/ConsignmentExports/UpdateExport/'+PreviousConsignmentId;
                }

                window.open(url, '_blank');
            }

            registerTradeApi(gridApi: uiGrid.IGridApi) {
                this.apiTrade = gridApi;
            }

            loadCountries() {
                var defered = this.$q.defer();

                this.countryService.getDropdownList().query({}, (data: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {
                    defered.resolve(data);
                }, () => {
                    defered.resolve([]);
                });


                return defered.promise;
            }

            addInternationalTrade_click() {
                
                    var code = '';

                     var zaTariffCode = this.Sku.CommercialInvoiceLine.CommercialInvoiceNationalMeasureCodeList.find(x => x.NationalMeasureCountryId === 249);

                    if (zaTariffCode) {
                        code = zaTariffCode.NationalMeasureCode.substring(0, 6);
                    } else {
                    // No matching element was found
                    }

                this.lookupService.openTariffLookup(undefined, Enum.EnumTariffCodeType.NationMeasure, true,code, this.$scope.commercialInvoiceUpdateCtrl.CommercialInvoice.OwnerEntity.Id).result.then(
                    (result: interfaces.tariff.INationalMeasureCodeLookup) => {
                        this.addInternationalTrade(result);
                    }, (errorResult) => {

                    });
            }

            editNationalMeasureCode(internationalTrade: interfaces.logistics.ICommercialInvoiceNationalMeasureCode) {

                var code = internationalTrade.NationalMeasureCode;
                if ( code === ''){
                    var zaTariffCode = this.Sku.CommercialInvoiceLine.CommercialInvoiceNationalMeasureCodeList.find(x => x.NationalMeasureCountryId === 249);

                    if (zaTariffCode) {
                        code = zaTariffCode.NationalMeasureCode.substring(0, 6);
                    } else {
                    // No matching element was found
                    }
                }


                this.lookupService.openTariffLookup(internationalTrade.NationalMeasureCountryId, Enum.EnumTariffCodeType.NationMeasure, true, code,this.$scope.commercialInvoiceUpdateCtrl.CommercialInvoice.OwnerEntity.Id,internationalTrade.CodeTypeClassificationValueId).result.then(
                    (lookupResult: interfaces.tariff.INationalMeasureCodeLookup) => {

                        this.bsLoadingOverlayService.wrap({
                        }, () => {
                            return this.countryService.get().get({ cntId: lookupResult.CountryId }, (countryResult) => {

                                internationalTrade.CodeTypeClassificationValueId = lookupResult.CodeTypeClassificationValueId;
                                internationalTrade.CodeTypeClassificationValueDescription = _.find(this.tariffTypes, (o) => { return o.Id === lookupResult.CodeTypeClassificationValueId }).Description;
                                internationalTrade.ImportDuty = lookupResult.DutyTypeCode;
                                internationalTrade.NationalMeasureCountryId = lookupResult.CountryId;
                                internationalTrade.NationalMeasureCode = lookupResult.NationalMeasureCode;
                                internationalTrade.NationalMeasureCountryCode = countryResult.Code;


                                if (this.apiTrade) {
                                    this.$timeout(() => {
                                        this.apiTrade.rowEdit.setRowsDirty([internationalTrade]);
                                    });
                                }

                            }).$promise;
                        });


                    }, (errorResult) => {

                    });
            }

            changeNationalMeasureCode(internationalTrade: interfaces.logistics.ICommercialInvoiceNationalMeasureCode) {
                this.apiTrade.rowEdit.setRowsDirty([internationalTrade]);
            }

            addInternationalTrade(nationalMeasureCodeLookup: interfaces.tariff.INationalMeasureCodeLookup) {
                this.bsLoadingOverlayService.wrap({
                }, () => {
                    return this.countryService.get().get({ cntId: nationalMeasureCodeLookup.CountryId }, (result) => {

                        var poNationalMeasure = {
                            CodeTypeClassificationValueId: nationalMeasureCodeLookup.CodeTypeClassificationValueId,
                            CodeTypeClassificationValueDescription: _.find(this.tariffTypes, (o) => { return o.Id === nationalMeasureCodeLookup.CodeTypeClassificationValueId }).Description,
                            ImportDuty: nationalMeasureCodeLookup.DutyTypeCode,
                            NationalMeasureCountryId: nationalMeasureCodeLookup.CountryId,
                            NationalMeasureCode: nationalMeasureCodeLookup.NationalMeasureCode,
                            NationalMeasureCountryCode: result.Code,
                            IsActive: true
                        };

                        this.Sku.CommercialInvoiceLine.CommercialInvoiceNationalMeasureCodeList.push(<interfaces.logistics.ICommercialInvoiceNationalMeasureCode>poNationalMeasure);
                        this.gvwCISTrade.data = this.Sku.CommercialInvoiceLine.CommercialInvoiceNationalMeasureCodeList;
                        if (this.apiTrade) {
                            this.$timeout(() => {
                                this.apiTrade.rowEdit.setRowsDirty([poNationalMeasure]);
                            });
                        }
                    }).$promise;
                });
            }

            loadTariffTypes() {
                return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                    classificationId: Enum.ClassificationEnum.TariffCodeType
                }, (resultList) => {
                    this.tariffTypes = resultList;
                }).$promise;
            }

            loadConsignmentTypeDefaults() {
                return this.consignmentService.GetConsignmentTypeDefaultsByConsignment(this.$scope.consignmentUpdateCtrl.consignmentID).get((data: interfaces.consignment.ConsignmentTypeDefaults) => {
                    this.consignmentTypeDefaults = data;
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise
            }

            getAllowPriceOverrideRight() {
                this.allowCostPriceOverride = false;
                return this.menuService.getGTSConnectMenuItem(575).get((result: interfaces.applicationcore.IMenu) => {
                    if (result.name) {
                        this.allowCostPriceOverride = true;
                    }
                }).$promise;
            }


            countryOfDecChanged(model: interfaces.applicationcore.IDropdownModel, entity: interfaces.logistics.ICommercialInvoiceCountryGroup) {
                if (model) {
                    this.setRowsDirtyCG(entity);
                }
            }

            setRowsDirtyCG(entity) {
                if (this.apiCG) {
                    this.apiCG.rowEdit.setRowsDirty([entity]);
                }
            }

            countryGroupChanged(model: interfaces.applicationcore.IDropdownModel, entity: interfaces.logistics.ICommercialInvoiceCountryGroup) {
                if (model) {
                    this.setRowsDirtyCG(entity);
                }
            }

            certificateNumberChanged(entity: interfaces.logistics.ICommercialInvoiceCountryGroup){
                if (this.apiCG) {
                    this.apiCG.rowEdit.setRowsDirty([entity]);
                }
            }


            gvwCISCountryGroup: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: false,
                useExternalFiltering: false,
                enableCellEdit: true,
                useExternalSorting: false,
                multiSelect: false,
                enableRowHeaderSelection: false,
                enableColumnResizing: true,
                enableFullRowSelection: false,
                useExternalPagination: false,
                enableHorizontalScrollbar: 2,
                rowEditWaitInterval: -1,
                onRegisterApi: (gridApi) => { this.registerCountryGroupApi(gridApi); },
                cellEditableCondition: () => { return this.$scope.commercialInvoiceUpdateCtrl.CommercialInvoice ? this.$scope.commercialInvoiceUpdateCtrl.CommercialInvoice.LockdownType < 2 : true },
                columnDefs: [
                    {
                        name: "EDIT",
                        displayName: "",
                        field: "ID",
                        cellTemplate: `
                        <div class="GridButton"> 
                            <button type="button" ng-click="grid.appScope.commercialInvoiceSkuUpdateCtrl.removeCG(row.entity)" class="btn btn-warning btn-sm" ng-disabled="grid.appScope.consignmentUpdateCtrl.consignment.LockdownType>3">
                                Delete
                            </button>
                        </div>`,
                        enableSorting: false,
                        enableColumnMenu: false,
                        enableCellEdit: false,
                        width: 55,
                        enableCellEditOnFocus: false,
                        headerCellTemplate: `
                        <div class="GridButton"> 
                            <button type="button"  class="btn btn-default btn-sm" ng-click="grid.appScope.commercialInvoiceSkuUpdateCtrl.insertCG()" ng-disabled="grid.appScope.consignmentUpdateCtrl.consignment.LockdownType>3">
                                <span class="fa fa-plus"></span>
                            </button>
                        </div>`
                    },
                    {
                        name: 'Country',
                        displayName: '* Country Of Declaration',
                        field: 'TariffCountry',
                        enableCellEditOnFocus: true,
                        cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD.Display}}</div>`,
                        editableCellTemplate: `
                                <form name="inputForm">
                                    <gts-dropdown ng-change="grid.appScope.commercialInvoiceSkuUpdateCtrl.countryOfDecChanged(model, row.entity)" ui-select-wrap name="{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.commercialInvoiceSkuUpdateCtrl.loadCountryList()"></gts-dropdown>
                                </form>`,
                        enableSorting: false,
                        width: 250
                    },
                    {
                        name: 'CountryGroup',
                        displayName: '* Country Group',
                        field: 'CountryGroup',
                        enableCellEditOnFocus: true,
                        cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD.Display}}</div>`,
                        editableCellTemplate: `
                                <form name="inputForm">
                                    <gts-dropdown ng-change="grid.appScope.commercialInvoiceSkuUpdateCtrl.countryGroupChanged(model, row.entity)" ui-select-wrap name="{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.commercialInvoiceSkuUpdateCtrl.loadCountryGroupList(row.entity.TariffCountry.Id)"></gts-dropdown>
                                </form>`,
                        enableSorting: false,
                        width: 250
                    }
                    ,
                    {
                        name: 'CountryGroupOrigin',
                        displayName: 'Origin Criteria',
                        field: 'CountryGroupOriginCriteria',
                        enableCellEditOnFocus: true,
                        cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD.Display}}</div>`,
                        editableCellTemplate: `
                                <form name="inputForm">
                                    <gts-dropdown ui-select-wrap name="{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.commercialInvoiceSkuUpdateCtrl.loadCountryGroupOriginList(row.entity.CountryGroup.Id)"></gts-dropdown>
                                </form>`,
                        enableSorting: false,
                        width: 250
                    }
                    ,
                    {
                        name: 'PreferenceCode',
                        displayName: 'Preference Code',
                        field: 'PreferenceCode',
                        enableCellEditOnFocus: true,
                        cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD.Display}}</div>`,
                        editableCellTemplate: `
                                <form name="inputForm">
                                    <gts-dropdown ui-select-wrap name="{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.commercialInvoiceSkuUpdateCtrl.loadPreferenceCodeList(row.entity.TariffCountry.Id)"></gts-dropdown>
                                </form>`,
                        enableSorting: false,
                        width: 250
                    }, {
                        name: "CertificateNumber",
                        displayName: "Certificate Number",
                        field: "CertificateNumber",
                        width: 300,
                        enableCellEdit: true,
                        enableCellEditOnFocus: true,              
                        editableCellTemplate: `
                                <form name="inputForm">
                                    <div ng-if="!row.entity.LinkedToRegister">
                                        <p class="input-group-sm"> 
                                            <input type="text" name="{{MODEL_COL_FIELD.$$hashKey}}-CertificateNumber" autocomplete="off" 
                                            ng-change="grid.appScope.commercialInvoiceSkuUpdateCtrl.certificateNumberChanged(row.entity)"
                                            ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor />
                                        </p>
                                     </div>
                                     <div ng-if="row.entity.LinkedToRegister">
                                     <p class="input-group-sm"> 
                                     {{row.entity.CertificateNumber}}                       
                                     </p>
                                  </div>                                     
                                </form>`,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`
                    }   , {
                        name: "LinkedToRegister",
                        displayName: "Linked to Register",
                        enableFiltering: false,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        cellTemplate: `<input type="checkbox" ng-disabled="true" ng-model="row.entity.LinkedToRegister">`,
                        editableCellTemplate: ` <form name="inputForm">
                                                    <p class="input-group-sm">
                                                        <input type="checkbox" ng-disabled="false" ng-model="row.entity.Active">
                                                    </p>
                                                </form>`,
                        width: 100
                    }
                    , {
                        name: "Active",
                        displayName: "Active",
                        enableFiltering: false,
                        enableCellEdit: true,
                        enableCellEditOnFocus: true,
                        cellEditableCondition: () => { return this.$scope.commercialInvoiceUpdateCtrl.CommercialInvoice ? this.$scope.commercialInvoiceUpdateCtrl.CommercialInvoice.LockdownType < 2 : true },
                        cellTemplate: `<input type="checkbox" ng-disabled="false" ng-model="row.entity.Active">`,
                        editableCellTemplate: ` <form name="inputForm">
                                                    <p class="input-group-sm">
                                                        <input type="checkbox" ng-disabled="false" ng-model="row.entity.Active">
                                                    </p>
                                                </form>`,
                        width: 75
                    }
                ]
            };

            registerCountryGroupApi(gridApi: uiGrid.IGridApi) {
                this.apiCG = gridApi;

                this.apiCG.edit.on.afterCellEdit(this.$scope, function (rowEntity, colDef, newValue, oldValue) {


                });
            }

            loadCountryList() {
                var defered = this.$q.defer();

                this.countryService.getDropdownList().query({}, (data: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {
                    defered.resolve(data);
                }, () => {
                    defered.resolve([]);
                });


                return defered.promise;
            }

            loadCountryGroupList(id) {
                if (this.Sku && this.Sku.CommercialInvoiceLine && id) {
                    return this.tariffCountryGroupService.getForDropDownByTargetCountry(id, this.targetCountryId).query().$promise;
                } else {
                    var defered = this.$q.defer();
                    defered.resolve([]);
                    return defered.promise;
                }
            }


            loadPreferenceCodeList(id) {
                if (this.Sku && this.Sku.CommercialInvoiceLine && id) {
                    return this.tariffPreferenceCodeService.getDropdownList().query({ tariffCountryId: id }).$promise;
                } else {
                    var defered = this.$q.defer();
                    defered.resolve([]);
                    return defered.promise;
                }
            }

            loadCountryGroupOriginList(id) {
                if (this.Sku && this.Sku.CommercialInvoiceLine && id) {
                    return this.tariffCountryGroupOriginService.getDropdownList().query({ countryGroupId: id }).$promise;
                } else {
                    var defered = this.$q.defer();
                    defered.resolve([]);
                    return defered.promise;
                }
            }

            insertCG() {
                this.Sku.CommercialInvoiceLine.CommercialInvoiceCountryGroupList.push(<interfaces.logistics.ICommercialInvoiceCountryGroup>{
                    Id: 0,
                    CommercialInvoiceSKUId: this.Sku.Id,
                    LinkedToRegister: false,
                    Active: true
                });
                this.gvwCISCountryGroup.data = this.Sku.CommercialInvoiceLine.CommercialInvoiceCountryGroupList;
            }


            removeCG(entity: interfaces.logistics.ICommercialInvoiceCountryGroup) {


                this.generalService.displayConfirmationBox("Confirm Remove", "Are you sure you want to remove the Country Group?").then((result) => {
                    var selectedRow: interfaces.logistics.ICommercialInvoiceCountryGroup = this.apiCG.selection.getSelectedRows()[0];
                    if (result && entity.Id <= 0) {
                        _.remove(this.Sku.CommercialInvoiceLine.CommercialInvoiceCountryGroupList, selectedRow);
                    } else if (result) {
                        this.bsLoadingOverlayService.wrap({
                            referenceId: 'ci.skuupdate'
                        },
                            () => {
                                return this.commercialInvoiceCountryGroupService.remove(entity.Id).remove((result) => {
                                    this.generalService.displayMessageHandler(result);
                                    if (!result.HasErrorMessage)
                                        this.loadSku(this.$stateParams.cisId, this.$stateParams.comId);
                                }, (errorResponse) => {
                                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                                }).$promise;
                            });
                    }
                });

            }

            loadProducts(searchText: string) {
                var defer = this.$q.defer();
                defer.resolve([]);
                return defer.promise;
            }

            loadVatRate(searchText: string) {
                if (this.Sku) {
                    return this.vatRateService.getDropdownList().query({ ownerEntityId: this.$scope.commercialInvoiceUpdateCtrl.CommercialInvoice.OwnerEntity.Id, countryId: this.$scope.commercialInvoiceUpdateCtrl.CommercialInvoice.SupplierCountryId }, () => {

                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
                } else {
                    var defer = this.$q.defer();
                    defer.resolve([]);
                    return defer.promise;
                }
            }

            vatRateChanged(model: interfaces.applicationcore.IDropdownModel) {
                if (model) {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'so.skuupdate'
                    },
                        () => {
                            return this.vatRateService.getVatRate().get({ mvrId: model.Id }, (vatRate: interfaces.master.IVatRate) => {
                                this.Sku.CommercialInvoiceLine.VATRateValue = vatRate.CurrentRate;
                                this.calculateVatValue();
                            }, (errorResponse) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                            }).$promise;
                        });
                } else {
                    this.Sku.CommercialInvoiceLine.VATRateValue = 0;
                    this.calculateVatValue();
                }
            }

            calculateVatValue() {
                if (!this.Sku.CommercialInvoiceLine.VATRateValue)
                    this.Sku.CommercialInvoiceLine.VATRateValue = 0;

                if (this.Sku.CommercialInvoiceLine.IsZeroRated)
                    this.Sku.VATValue = 0;
                else
                    this.Sku.VATValue = Math.round((this.Sku.SellingValue * (this.Sku.CommercialInvoiceLine.VATRateValue / 100)) * 100) / 100;
            }

            loadSKUs(searchText: string) {
                if (this.Sku) {
                    return this.skuService.getDropdownList(this.$scope.commercialInvoiceUpdateCtrl.CommercialInvoice.OwnerEntity.Id, searchText).query(() => {

                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
                } else {
                    var defer = this.$q.defer();
                    defer.resolve([]);
                    return defer.promise;
                }
            }


            loadSuppliers(searchText: string) {
                if (this.Sku) {
                    return this.supplierService.getSupplierDropdown(this.$scope.commercialInvoiceUpdateCtrl.CommercialInvoice.OwnerEntity.Id, searchText).query(() => {

                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
                } else {
                    var defer = this.$q.defer();
                    defer.resolve([]);
                    return defer.promise;
                }
            }

            skuChanged(model: interfaces.applicationcore.IDropdownModel) {
                if (model) {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'ci.skuupdate'
                    },
                        () => {
                            return this.loadSkuDefaults(model.Id);
                        });
                }

            };

            loadSkuDefaults(Id: number) {

                return this.commercialInvoiceSkuService.getSKUDefaults().query({ skuId: Id, cisId: this.comId }, (result: interfaces.master.ISKU) => {
                    this.breadCrumbDesc = this.Sku.CommercialInvoiceLine.SequenceNumber + " - " + result.Code;
                    
                    this.Sku.CommercialInvoiceLine.Product = <interfaces.applicationcore.IDropdownModel>{
                        Id: result.ProductId,
                        Code: result.Product.Code,
                        Description: result.Product.ShortDescription,
                        Display: result.Product.Code + " - " + result.Product.ShortDescription,
                        Selected: true
                    };

                    this.Sku.CommercialInvoiceLine.ProductShortLocaleDescription = result.Product.ShortDescription;
                    this.Sku.CommercialInvoiceLine.ProductLongLocaleDescription = result.Product.ShortDescription;

                    this.Sku.CommercialInvoiceLine.Supplier = result.DefaultSupplier;

                    if (result.DefaultVatRate) {
                        this.Sku.CommercialInvoiceLine.VatRateRate = result.DefaultVatRate;
                        this.Sku.CommercialInvoiceLine.VATRateValue = result.DefaultVatRateValue;
                    }

                    this.Sku.CommercialInvoiceLine.OrderUoM = <interfaces.applicationcore.IDropdownModel>{
                        Id: result.Product.OrderUnitOfMeasureId,
                        Code: result.Product.UnitOfMeasureCode,
                        Description: result.Product.UnitOfMeasureDescription,
                        Display: result.Product.UnitOfMeasureCode
                    }

                    this.Sku.CommercialInvoiceLine.PricingUoM = <interfaces.applicationcore.IDropdownModel>{
                        Id: result.Product.PricingUnitOfMeasureId,
                        Code: result.Product.UnitOfPricingCode,
                        Description: result.Product.UnitOfPricingDescription,
                        Display: result.Product.UnitOfPricingCode
                    }

                    this.Sku.PackageConfiguration = result.PackConfiguration;
                    this.Sku.Colour = result.ProductColour;
                    this.Sku.Size = result.ProductSize;

                    this.Sku.CommercialInvoiceLine.ProductCategoryHierarchicalId = result.Product.ProductCategoryId;
                    this.Sku.CommercialInvoiceLine.ProductCategoryHierarchicalCode = result.Product.CategoryCode;
                    this.Sku.CommercialInvoiceLine.ProductCategoryDescription = result.Product.CategoryDescription;
                    this.Sku.UoMList = [];
                    this.Sku.UoMList = _.map(result.ProductUnitOfMeasures, (o: interfaces.master.IProductUnitOfMeasure) => {
                        return <interfaces.logistics.ICommercialInvoiceSKUUoM>{
                            CommercialInvoiceSKUId: this.Sku.Id,
                            UoM: <interfaces.applicationcore.IDropdownModel>{
                                Id: o.UnitOfMeasureId,
                                Code: o.UOMCode,
                                Description: o.UOMDescription,
                                Display: o.UOMCode + ' - ' + o.UOMDescription
                            },
                            Quantity: o.UnitOfMeasureQuantity,
                            IsActive: true
                        }
                    });

                    this.gvwCISUoM.data = this.Sku.UoMList;
                    this.Sku.PricingUOMConversion = result.OrderUnitOfMeasureConversion;
                    this.Sku.OrderedQty = 0;
                    this.Sku.PriceQty = 0;
                    this.Sku.SellingPrice = 0;
                    this.Sku.SellingValue = 0;
                    this.Sku.BaseCurrencySellingPrice = 0;
                    this.Sku.BaseCurrencySellingValue = 0;
                    this.Sku.GrossWeight = result.GrossWeight;
                    this.Sku.NettWeight = result.NettWeight;
                    this.Sku.Cube = result.Cube;
                    this.recalculateValues();

                }).$promise;
            }

            loadProductSizes() {
                var defered = this.$q.defer();

                if (this.Sku && this.Sku.CommercialInvoiceLine) {
                    this.productSizeService.getDropdownList().query({ ownerEntityId: this.$scope.commercialInvoiceUpdateCtrl.CommercialInvoice.OwnerEntity.Id }, (data: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {
                        defered.resolve(data);
                    }, () => {
                        defered.resolve([]);
                    });
                } else {
                    defered.resolve([]);
                }

                return defered.promise;
            }

            loadPackConfigurations(searchText: string) {
                var defered = this.$q.defer();

                if (this.Sku && this.Sku.CommercialInvoiceLine) {
                    this.packConfigurationService.getDropdownList().query({
                        ownerEntityId: this.$scope.commercialInvoiceUpdateCtrl.CommercialInvoice.OwnerEntity.Id,
                        searchText: searchText,
                    }, (data: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {
                        defered.resolve(data);
                    }, () => {
                        defered.resolve([]);
                    });
                } else {
                    defered.resolve([]);
                }

                return defered.promise;
            }

            loadProductColours() {
                var defered = this.$q.defer();

                if (this.Sku && this.Sku.CommercialInvoiceLine) {
                    this.productColourService.getDropdownList().query({ ownerEntityId: this.$scope.commercialInvoiceUpdateCtrl.CommercialInvoice.OwnerEntity.Id }, (data: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {
                        defered.resolve(data);
                    }, () => {
                        defered.resolve([]);
                    });
                } else {
                    defered.resolve([]);
                }

                return defered.promise;
            }

            useCustomsValueChanged(){
                if (!this.Sku.UseCustomsValue){
                    this.Sku.IsGiveAway = false; 
                }
            }

            isGiveAwayChanged(){

                if (this.Sku.IsGiveAway){
                    this.Sku.UseCustomsValue = true;
                }
                else{
                    this.Sku.UseCustomsValue = false;
                }

                this.recalculateValues();
            }

            //Forwards
            recalculateValues() {
                this.Sku.PriceQty = Math.round(((this.Sku.PricingUOMConversion * this.Sku.OrderedQty) * 100000)) / 100000;
                this.Sku.SellingValue = this.Sku.SellingPrice * this.Sku.PriceQty;

                this.calculateVatValue();

                this.Sku.BaseCurrencySellingValue = this.Sku.BaseCurrencySellingPrice * this.Sku.OrderedQty;


                this.Sku.NettWeightValue = this.Sku.NettWeight * this.Sku.OrderedQty;
                this.Sku.GrossWeightValue = this.Sku.GrossWeight * this.Sku.OrderedQty;
                this.Sku.CubeValue = this.Sku.Cube * this.Sku.OrderedQty;
                this.Sku.StatisticalQtyValue = this.Sku.StatisticalQty * this.Sku.OrderedQty;

                this.Sku.NettWeightValue = this.myRound(this.Sku.NettWeightValue, 6);
                this.Sku.GrossWeightValue = this.myRound(this.Sku.GrossWeightValue, 6);
                this.Sku.CubeValue = this.myRound(this.Sku.CubeValue, 6);
                this.Sku.StatisticalQtyValue = this.myRound(this.Sku.StatisticalQtyValue, 6);


            }

            //Backwards
            recalculateValuesBackwards() {
                this.Sku.StatisticalQty = this.Sku.StatisticalQtyValue / this.Sku.OrderedQty;
                this.Sku.NettWeight = this.Sku.NettWeightValue / this.Sku.OrderedQty;
                this.Sku.GrossWeight = this.Sku.GrossWeightValue / this.Sku.OrderedQty;
                this.Sku.Cube = this.Sku.CubeValue / this.Sku.OrderedQty;

                this.Sku.NettWeight = this.myRound(this.Sku.NettWeight, 6);
                this.Sku.GrossWeight = this.myRound(this.Sku.GrossWeight, 6);
                this.Sku.Cube = this.myRound(this.Sku.Cube, 6);
                this.Sku.StatisticalQty = this.myRound(this.Sku.StatisticalQty, 6);

                this.Sku.BaseCurrencySellingPrice = this.Sku.BaseCurrencySellingValue / this.Sku.OrderedQty;

                this.Sku.PricingUOMConversion = this.Sku.PriceQty / this.Sku.OrderedQty;
                this.Sku.SellingPrice = this.Sku.SellingValue / this.Sku.PriceQty;


                this.calculateVatValue();
            }

            myRound(value, places) {
                var multiplier = Math.pow(10, places);

                return (Math.round(value * multiplier) / multiplier);
            }

            varianceAuthorisation_Click() {

                this.displayVarianceAuthorisation().then((result) => {
                    if (result) {
                        this.varianceAuthUser = result;
                        this.commercialInvoiceSkuService.ValidateUser().save((this.varianceAuthUser), (result: interfaces.applicationcore.IMessageHandler) => {
                            this.generalService.displayMessageHandler(result);

                            this.Sku.CommercialInvoiceLine.varianceAuthorisedUserId = this.varianceAuthUser.Id;

                        }, (failureData) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                            this.$anchorScroll("topAnchor");
                        });
                    }
                });

            }


            displayVarianceAuthorisation() {
                //Get delivery status
                return this.$uibModal.open({
                    animation: true,
                    template: `
                        <div class="modal-content" >
                            <form name="varianceAuthorisation">
                                <div class="gts-padding">
                                    <fieldset id="varianceAuth">
                                    <legend>
                                        Commercial Invoice Variance Authorisation
                                    </legend>                                            
                                            <div class="row vertical-align">
                                                <div class="col-md-4">
                                                    <req></req>
                                                    <label>Username: </label>
                                                </div>
                                                <div class="col-md-6">
                                                    <p class="input-group-sm has-feedback" ng-class="{'has-error': users.$invalid}">
                                                        <gts-dropdown name="users" ng-model="varianceAuthorisationCtrl.user" form="varianceAuthorisation" load-data="varianceAuthorisationCtrl.loadUserList()" required="true"></gts-dropdown>
                                                    </p>
                                                </div>                                               
                                            </div>  
                                            <div class="row vertical-align">
                                                <div class="col-md-4">
                                                    <req></req>
                                                    <label>Password: </label>
                                                </div>
                                                <div class="col-md-6">
                                                    <p class="input-group-sm has-feedback" ng-class="{'has-error': password.$invalid}">
                                                           <input type="password" name="password" autocomplete="off" ng-model="varianceAuthorisationCtrl.password" form="varianceAuthorisation"  required="true"   class="form-control"/>
                                                    </p>
                                                </div>                                               
                                            </div> 
                                            <div class="gts-padding pull-right">                                               
                                                    <button type="button" class="btn btn-primary" ng-disabled="varianceAuthorisation.$invalid" ng-click="varianceAuthorisationCtrl.ok()">Ok</button>
                                                    <button type="button" class="btn btn-info" ng-click="varianceAuthorisationCtrl.cancel()">Cancel</button>
                                            </div>                                       
                                    </fieldset>
                                </div>
                            </form>
                        </div>
                    `
                    ,
                    controller: class varianceAuthorisationCtrl {
                        user: interfaces.applicationcore.IDropdownModel;
                        password: string;
                        constructor($scope, private $uibModalInstance: angular.ui.bootstrap.IModalInstanceService, private commercialInvoiceSkuService: interfaces.logistics.ICommercialInvoiceSkuService) {

                        }

                        cancel() {
                            this.$uibModalInstance.dismiss();
                        }

                        ok() {
                            this.user.Code = this.password;
                            this.$uibModalInstance.close(this.user);
                        }



                        //Load Container ISO.
                        loadUserList() {
                            return this.commercialInvoiceSkuService.getUserAuthorisationDropdownList().query(
                                () => {

                                }, (failureData) => {
                                }).$promise;
                        }
                    },
                    controllerAs: "varianceAuthorisationCtrl",
                    size: "md",
                    resolve: {
                    }
                }).result;
            }

        }

        angular.module("app").controller("commercialInvoiceSkuUpdateCtrl", controllers.logistics.commercialInvoiceSkuUpdateCtrl);
    }
}